export default {
  colors: {
    rasPrimary: '#3625d3',
    background: '#ffffff',
    text: '#121212',
    primary: '#121212',
    secondary: '#e9e9e9',
    tertiary: '#f3f3f3',
    highlight: '#3625d3',
    footer: {
      background: '#f8f9f9',
      text: 'rgba(59,59,59, .5)',
      highlight: '#555'
    },
    nav: {
      text: '#121212',
      highlight: '#121212'
    }
  },
  fonts: {
    body:
      'Josefin Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  sizes: {
    maxWidth: '960px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}
